.custom_carousel .custom-control-prev,
.custom_carousel .custom-control-next 
{
    opacity: 0.85;
    transition: .15s ease;
}
.custom-control-prev{
    position:absolute;
    margin-top:590px;
    margin-left:20px;
    z-index:1;
    width:15%;
    color:#fff;
    opacity:.5;
}
.custom-control-next{
    position:absolute;
    margin-top:590px;
    margin-left:90px;
    z-index:1;
    width:15%;
    color:#fff;
    opacity:.5;
}
.custom_carousel .custom-control-prev:hover,
.custom_carousel .custom-control-next:hover 
{
    opacity: 1;
}
.banner-container-banner2 {
    display: flex;
    flex-direction: row;
    top:0;
    position: absolute;
    left: 0;
    height: 850px;
    width: 100%;
}
.banner-div1 {
    width: 100%;
    left: 0%;
    right: 50%;
    top: 0%;
    bottom: 0%;
    height: 850px;
}
.theme2-banner-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 0 10%;
    gap: 2rem;
    padding-top: 50px;
    margin-bottom: 60px;
}
.banner-div2 {
    height: 850px;
    left: 0%;
    top: 0%;
    width:100%;
}
.banner2-div {
    width: 320px;
    position: absolute;
    bottom:10%;
    right:6%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: space-between;
}
.banner2-heading {
    cursor: pointer;
    width:320px;
    white-space: normal;
    display: flex;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; */
}
.banner2-paragraph {
    width:320px;
    margin-top: 20px;
    height: max-content;
    white-space: normal;
    display: flex;
    /* display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; */

}
.banner2-button {
    width:320px;
    margin-top: 40px;
}
.banner-container {
    display: flex;
    width: 100%;
}

@media screen and (min-width: 1400px) {
    .banner2-div {
    max-width: 300px;
    }
}

@media screen and (min-width: 1024px) {
.theme2-banner-text {
    padding-left: 80px;
    padding-right: 80px;
    }
}

@media screen and (max-width: 1024px) {
.banner-container-banner2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1px;
    }
}

@media screen and (min-width:600px) and (max-width: 1023.9px) {
.custom-control-prev{
    margin-top:76%;
    margin-left:50px;
    }
.custom-control-next{
    margin-top:76%;
    margin-left:120px;
    }
.banner-div1{
    width: 100%;
    height:600px;
    }
.banner-div2{
    width: 100%;
    height:600px;
    }
.theme2-banner-text{
    margin-top: 15%;
    }
.banner2-div{
    width: 307px;
    position: absolute;
    bottom:80px;                                                                
    right:90px;
    }
}

@media screen and (min-width:500px) and (max-width: 600px) {
.custom-control-prev{
    margin-top:84%;
    margin-left:39%;
    }
.custom-control-next{
    margin-top:84%;
    margin-left:52%;
    }
.banner-div1{
    width: 100%;
    height:560px;
    }
.banner-div2{
    width: 100%;
    height:667px;
    }
.theme2-banner-text{
    margin-top: 15%;
    }
.banner2-div{
    margin:auto;
    width: 315px;
    position: absolute;
    bottom:120px;
    right:30px;
    left:30px;
    }
}

 @media screen and (min-width:400px) and (max-width:500px) {
.custom-control-prev{
    margin-top:98%;
    margin-left:37%;
    }
.custom-control-next{
    margin-top:98%;
    margin-left:53%;
    }
.banner-div1{
    width: 100%;
    height:560px;
    }
.banner-div2{
    width: 100%;
    height:667px;
    }
.theme2-banner-text{
    margin-top: 15%;
    }
.banner2-div{
    margin:auto;
    width: 315px;
    position: absolute;
    bottom:120px;
    right:30px;
    left:30px;
    }
} 

@media screen and (max-width:400px){
.custom-control-prev{
    margin-top:110%;
    margin-left:33%;
    }
.custom-control-next{
    margin-top:110%;
    margin-left:53%;
    }
.banner-div1{
    width: 100%;
    height:560px;
    }
.banner-div2{
    width: 100%;
    height:667px;
    }
.theme2-banner-text{
    margin-top: 15%;
    }
.banner2-div{
    margin:auto;
    width: 315px;
    position: absolute;
    bottom:100px;
    right:30px;
    left:30px;
    }
}

