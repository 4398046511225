.progress {
    overflow: visible;
    width: 100%;
    height: 100%;
}
.progress-bar {
    z-index: 0;
    position: relative;
    overflow: visible;
}
.progress-bar span {
    background-color: #ffffff;
    position: absolute;
    bottom: 34px;
    font-size: 10px;
    line-height: 10px;
    padding: 10px 15px 10px 15px;
    right: -50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: black;
}
.progress-bar span:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #ffffff 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50px;
    margin-left: -12px;
    position: absolute;
    width: 0;
}
.progress-bar p {
    margin-bottom: 5px;
    color: #8C8C8C;
    font-weight: 400;
    font-size: 12px;
}
.progress-bar h6 {
    width: 60px;
    margin: 0;
    font-style: normal;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.min-max-value{
    margin-top: -33px;
    height: 40px;
    width: 100%;
    display:flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    font-style: normal;
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}
.current_points {
    overflow:visible ;
    color: white;
    z-index: 1;
}
.max_points {
    z-index: 1;
    color: white;
}