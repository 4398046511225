.no-results-text{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 12px !important;
}
.w-100{
    width: 100% !important;
}

.searchButtonGroup{
    margin-bottom: 10px;
    padding: 1rem;
}