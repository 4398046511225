.header-left{
    width: 40%;
}
.header-right{
    width: 60%;
}
.mobile-header-logo{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    align-items: center;
}
.basket_buttons{
    width:100%;
    gap: 15px;
}
.basket_buttons>button:nth-child(1){
    width:45%;
    justify-content: center;
}
.basket_buttons>button:nth-child(2){
    width:55%;
    justify-content: center;
}

@media (max-width: 768px) {
    .header-left{
        width: 100vw;
    }
    .header-right{
        width: 100vw;
    }
}

/* Header Theme 1 styles */
.points-notification-block {
    display: flex; 
    flex-direction: column;
    padding: 10px;
    position: absolute;
    width: 100%
}
@media (min-width: 768px) {
    .points-notification-block {
        width: 60%;
        padding: 40px;
    }
}

@media (max-width: 360px) {
    .mobileNav {
        width: calc(100% - 80px);
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-left: 0.3rem;
    }
}

@media (max-width: 427px) {
    .mobileNav {
        width: calc(100% - 90px);
        justify-content: space-between; 
        display: flex; 
        flex-direction: row;
    }

    .navbar-expand .navbar-nav .nav-link {
        padding-right: 0;
    }

    .containerFluidCustom {
        padding-left: 6px;
    }
}

@media (max-width: 576px) {
    .mobileNav {
        justify-content: flex-end; 
        display: flex; 
        flex: 1;
    }
    .mobile-header-logo{
        padding: 5px;
    }
}

.headerTopBar {
    backdrop-filter: brightness(90%);
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0) !important;
}