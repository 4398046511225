svg {overflow: visible;}

/* Button 1 */
.buttonMedium1, .roundIconButton1{
    background-color : #E56B2E
}
.buttonMedium1:hover, .roundIconButton1:hover{
    background-color : #B25324
}

/* Button 2 */
.buttonMedium2, .roundIconButton2{
    background-color : #000000
}
.buttonMedium2:hover, .roundIconButton2:hover{
    background-color : #E66B2E
}

/* Button 3 */
.buttonMedium3, .roundIconButton3{
    color: #222222;
}
.buttonMedium3:hover, .roundIconButton3:hover{
    color : #E56B2E
}
.buttonMedium3 path, .roundIconButton3 path{
    fill: #222222
}
.buttonMedium3:hover path, .roundIconButton3:hover path{
    fill : #E56B2E
}

/* Button 4 */
.buttonLarge4, .buttonMedium4, .roundIconButton4{
    color: #222222;
    border: 1px solid #BFBFBF;
}
.buttonLarge4:hover, .buttonMedium4:hover, .roundIconButton4:hover{
    color : #E56B2E;
    border: 1px solid #E56B2E;
}
.buttonLarge4 path, .buttonMedium4 path, .roundIconButton4 path{
    fill: #222222
}
.buttonLarge4:hover path, .buttonMedium4:hover path, .roundIconButton4:hover path{
    fill : #E56B2E
}

/* Icon Button 1 */
.iconButtonLarge1, .iconButtonMedium1{
    background-color: #FFFFFF;
    border: 1px solid #BFBFBF;
}
.iconButtonLarge1:hover, .iconButtonMedium1:hover{
    background-color: #FF4747;
    border: none;
}
.iconButtonLarge1:hover path, .iconButtonMedium1:hover path{
    fill: #FFFFFF;
    stroke: #FFFFFF;
}