.flexDiv {
    display: flex;
    flex-direction: column;
}
.banner-container{
    position: relative;
    display: flex;
    flex: 1;
    margin-left: -10px;
    margin-right: -10px;
    max-width: 100vw;
}
.ThemeONEhomeContent{
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.ThemeTWOhomeContent{
    margin-top: 905px;
}
.ThemeTHREEhomeContent{
    margin-top: 905px;
}
.ThemeFOURhomeContent{
    margin-top: 905px;
}
.banner-top{
    position: relative;
    min-height: 200px;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 70px;
}

.banner-top-1 {
    flex: 1;
    gap: 30px;
    min-height: 200px;
    padding-right: 15px;
    margin-left: 30px;
}

.banner-top-2 { flex: 0.5; position: absolute; width: 38%; right: 30px; bottom: -140px;}
.banner-top-2-mobile {
    align-self: center;
    width: 80vw;
    margin-top: -50px;
}

.bannerProduct {
    height: 420px !important
}

.product-large-2-info{
    padding: 20px 0;
    flex-direction: row;
}

.homeContentWrapper {
    margin-top: 50px;
}

@media screen and (min-width: 768px) {
    .banner-container{
        margin-left: -30px;
        margin-right: -30px;
    }
    .ThemeTWOhomeContent{
        margin-top: 900px;
    }
    .ThemeTHREEhomeContent{
        margin-top: 980px;
    }
    .ThemeFOURhomeContent{
        margin-top: 1100px;
    }
    .bannerProduct{
        height: 420px !important
    }
}

@media screen and (min-width: 900px) {
    .banner-top{
        padding-top: 70px;
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media screen and (min-width: 1024px) {
    .banner-container{
        margin-left: -80px;
        margin-right: -80px;
    }
    .homeContentWrapper {
        margin-top: 100px;
    }
    .ThemeONEhomeContent{
        margin-top: 300px;
    }
    .ThemeTWOhomeContent{
        margin-top: 600px;
    }
    .ThemeTHREEhomeContent{
        margin-top: 500px;
    }
    .ThemeFOURhomeContent{
        margin-top: 1150px;
    }
    .banner-top{
        flex-direction: row ;
        flex: 1;
        padding-left: 70px;
        min-height: 350px;
        padding-top: 120px;
    }
    .banner-top-1 {
        flex: 0.55;
        max-width: 100%;
        min-height: 360px;
    }
    
    .bannerProduct{
        height: 540px !important;
    }
    .product-large-2-points{
        align-self: flex-end;
    }
    .product-large-2-info{
        flex-direction: column;
    }
}

@media screen and (min-width: 1280px) {
    .banner-container{
        margin-left: -100px;
        margin-right: -100px;
    }
}

@media screen and (min-width: 1400px) {
    .ThemeFOURhomeContent{
        margin-top: 840px;
    }
}

@media screen and (max-width: 1024px) {
    .product-large-2-info{
        align-items: center;
    }
    .product-large-2-points-button{
        min-width: 100px;
        align-items: center;
        justify-content: center;
    }
}
