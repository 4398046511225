.banner-container-banner3 {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 0;
    top:80px;
    height: 600px;
    width: 100%;
}

.banner3-div1 {
    width: 100%;
    height: 100%;
}

.banner3-div2 {
    width: 100%;
    height: 100%;
}

.theme3-banner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    padding: 0 10%;
    gap: 2rem;
    padding-top: 50px;
}

@media screen and (min-width: 1024px) {
    .theme3-banner-text {
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (max-width: 1024px) {
    .banner-container-banner3 {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 1024px;
        padding-bottom: 1px;
    }

    .banner3-div1 {
        width: 100%;
        height: 500px;
    }

    .banner3-div2 {
        width: 100%;
        height: 524;
    }

    .theme3-banner-text {
        margin-top: 12%;
        margin-bottom: 100px;
    }

    .carousel-inner {
        height: 100%;
    }
}

@media screen and (max-width: 480px) {
    .theme3-banner-text {
        margin-top: 15%;

    }

    .theme3-banner-text buttton {
        margin-bottom: 100px;
    }
}

@media screen and (min-width:480px) and (max-width: 576px) {
    .theme3-banner-text {
        margin-top: 15%;
    }
}