#orders-block{
    border-radius: 14px;
    overflow: hidden;
}
.order-summary {
    display: flex;
    flex: 1 1;
    gap: 3rem;
    padding: 30px;
    flex-direction: column
}
.order-summary-one{
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 1rem;
    flex-direction: column;
}
.order-summary-two{
    display: flex;
    flex: 1;
    justify-content: space-around;
    gap: 1rem;
    flex-direction: column;
}
.order-number, .order-status, .order-date, .order-track {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.order-items-container{
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 2rem;
    flex-direction: column-reverse;
}
.order-items-address{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.order-item-container{
    display: flex;
    cursor: pointer;
    gap: 2rem;
}
.order-item-image{
    height: 293px;
    width: 100%;
    border-width: 0.5px;
    border-style: solid;
}
.order-items {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
}
.order-item-wrapper{
    padding: 40px 0;
    display: flex;
    flex: 1;
    width: 100%;
    max-width: 650px;
    gap: 2.5rem;
    flex-direction: column;
    border-width: 0 0 1px 0;
    border-style: solid;
}
.order-item-info{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
}
.order-item-details{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
}

.order-items{
    display: flex;
    flex-direction: column;
    padding: 40px;
}

@media (min-width: 550px) {
    .order-item-image{
        height: 175px;
        width: 140px;
    }
    .order-item-wrapper{
        flex-direction: row;
    }
    .order-summary-one{
        display: flex;
        flex: 1;
        justify-content: space-between;
        gap: 1rem;
        flex-direction: column;
    }
    .order-summary-two{
        display: flex;
        flex: 1;
        justify-content: space-around;
        gap: 1rem;
        flex-direction: column-reverse;
    }
    .order-summary{
        flex-direction: row;
    }
}
@media (min-width: 1024px) {
    .order-summary-one{
        display: flex;
        flex: 1;
        justify-content: space-between;
        gap: 1rem;
        flex-direction: row
    }
    .order-summary-two{
        display: flex;
        flex: 1;
        justify-content: space-around;
        gap: 1rem;
        flex-direction: row
    }
    .order-items{
        padding: 0;
    }
    .order-items-address{
        max-width: 327px;
    }
    .order-items-container{
        flex-direction: row;
        padding: 40px;
    }
}

#orders-block.MuiAccordion-root{
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
