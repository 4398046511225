.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next 
{
    opacity: 0.85;
    transition: 'opacity .15s ease';
}

.custom-carousel .carousel-control-prev:hover,
.custom-carousel .carousel-control-next:hover 
{
    opacity: 1;
}
