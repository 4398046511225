.drawer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 480px;
    background: white;
    height: fit-content;
    max-height: 100vh;
    position: absolute;
    right: -480px;
    top: 0;
    box-shadow: 0px 0px 100px rgba(34, 34, 34, 0.25);
    transition-duration: 0.2s, 0.5s;
    transition-property: right, opacity;
    transition-timing-function: ease-out;
    overflow: hidden;
    opacity: 0;
    z-index: 3;
}

.drawer.show {
    opacity: 1;
    overflow-y: scroll;
    width: 100%;
    right: 0;
    max-width: 480px;
    transition-duration: 0.2s, 0.5s;
    transition-property: right, opacity;
    transition-timing-function: ease-in-out;
    overscroll-behavior: contain
}

.page-wrapper.overlay {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
}