.ThemeONEcompetition_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:80px;
}
.ThemeTWOcompetition_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:0;
}
.ThemeTHREEcompetition_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:0px;
}
.ThemeFOURcompetition_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:80px;
}
.ThemeONEcompetition_title{
    margin-top: 780px;
}
.ThemeTWOcompetition_title {
    margin-top: 700px;
}
.ThemeTHREEcompetition_title {
    margin-top: 700px;
}
.ThemeFOURcompetition_title{
    margin-top: 780px;
}
.competition_title {
    padding-left: 10%;
    padding-right: 10%;
}

.prize-products {
    display: none;
}


.competition-content{
    flex: 1;
}

@media screen and (min-width: 1280px) {
    .post-title {
        padding-left: 100px;
        padding-right: 100px;
    }
}

@media screen and (min-width: 1024px) {
	.prize-products {
        display: flex;
    }

    .competition-content{
        flex: 0.5;
    }
}