.ThemeONEchristmas_calendar_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:80px;
}
.ThemeTWOchristmas_calendar_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:0;
}
.ThemeTHREEchristmas_calendar_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:0px;
}
.ThemeFOURchristmas_calendar_wrapper {
    position: absolute;
    width:100%;
    left:0;
    right:0;
    top:80px;
}
.ThemeONEchristmas_calendar_title{
    margin-top: 780px;
}
.ThemeTWOchristmas_calendar_title {
    margin-top: 700px;
}
.ThemeTHREEchristmas_calendar_title {
    margin-top: 700px;
}
.ThemeFOURchristmas_calendar_title {
    margin-top: 780px;
}
.christmas_calendar_title {
    padding-left: 10%;
    padding-right: 10%;
}

.christmas_calendar-content{
    flex: 1;
}

@media screen and (min-width: 1024px) {
	.christmas_calendar-content{
        flex: 0.5;
    }
}

.tooltip-content {
    padding: 15px;
    background-color: white;
    position: absolute;
    border-radius: 8px;
    z-index: 10;
    /* max-width: 550px; */
    display: flex;
    flex-direction: column;
    transition: opacity 2s;
    opacity: 1;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

@media screen and (min-width: 768px) {
    .tooltip-content {
        max-width: 550px;
    }
}

.tooltip {
    padding: 10px;
    width: 28px;
    height: 28px;
}

.tooltip-text {
    font-size: 14px;
}

@media screen and (min-width: 426px) {
	.tooltip {
        padding: 10px;
        width: 30px;
        height: 30px;
    }

    .tooltip-text {
        font-size: 17px;
    }

}

@media screen and (min-width: 600px) {
	.tooltip {
        padding: 10px;
        width: 35px;
        height: 35px;
    }

    .tooltip-text {
        font-size: 19px;
    }

}

@media screen and (min-width: 768px) {
	.tooltip {
        padding: 15px;
        width: 50px;
        height: 50px;
    }

    .tooltip-text {
        font-size: 28px;
    }
}

@media screen and (min-width: 1024px) {
	.tooltip {
        padding: 20px;
        width: 70px;
        height: 70px;
    }

    .tooltip-text {
        font-size: 34px;
    }
}

@media screen and (min-width: 1439px) {
	.tooltip {
        padding: 25px;
        width: 80px;
        height: 80px;
    }

    .tooltip-text {
        font-size: 36px;
    }
}

@media screen and (min-width: 2560px) {
	.tooltip {
        padding: 30px;
        width: 85px;
        height: 85px;
    }

    .tooltip-text {
        font-size: 38px;
    }
}

.calendarImage {
    min-height: 700px;
}

@media screen and (max-width: 1024px) {
    .calendarImage {
        aspect-ratio: 1.5;
        min-height: 0px;
    }
}

.product-image{
    height: 293px;
    width: 100%;
    border-width: 0.5px;
    border-style: solid;
}

@media (min-width: 550px) {
    .product-image{
        height: 160px;
        width: 118px;
    }
}