.HighlightedProductBlock {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    position: relative;
}

.highlightedProductText { 
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: flex-start;
    width: 34.2vw;
    max-width: 660px;
}
.HighlightedProductBlock .highlightedProduct{
    box-shadow: 0px 0px 3px 1px rgba(34, 34, 34, 0.15);
    max-width: 660px;
}


@media screen and (max-width: 1024px) {
    .HighlightedProductBlock .highlightedProduct {
        width: 90vw;
        max-width: 100%;
    }
    .highlightedProductText {
        width: 100%;
        margin-bottom: 2rem;
    }
}
@media screen and (min-width: 1024px) {
    .HighlightedProductBlock {
        flex-direction: row;
        gap: 15%;
    }
}


/* 
@media screen and (max-width: 500px) {
    .HighlightedProductBlock {
        margin-top: 700px;
    }
}

@media screen and (min-width: 500px) and (max-width: 768px) {
    .HighlightedProductBlock {
        margin-top: 700px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .HighlightedProductBlock {
        margin-top: 550px;
    }
} 
*/
