.have-you-seen-products, .featured-products {
    display: none;
}
.post-content{
    flex: 1;
}
@media screen and (min-width: 1024px) {
	.have-you-seen-products, .featured-products {
        display: flex;
    }
    .post-content{
        flex: 0.5;
    }
    .post-campaign-content{
        flex: 0.6;
    }
}







