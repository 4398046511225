.faq-section {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    justify-content: space-between;
    gap: 30px
}

.faq-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.faq-title-item {
    display: flex;
    min-height: 80px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.faq-details-section{
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}
.faq-close-icon{
    display: none;
}

.faq-content{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
    padding: 4rem;
    background: white;
    z-index: 2;
    padding-top: 180px;
}
.faq-modal-hide{
    display: none;
}

.faq-close-icon{
    display: flex;
    align-self: flex-end;
    cursor: pointer;
}

.faq-content-div{
    display:flex;
    flex-direction:row;
    gap:36px;
}

.like-dislike-div{
    gap:100px;
}

.like-button{
background: #FFFFFF;
border: 1px solid #BFBFBF;
border-radius: 50px;
height:48px;
width:48px;
margin-bottom: 15px;
}

.dislike-button{
background: #FFFFFF;
border: 1px solid #BFBFBF;
border-radius: 50px;
height:48px;
width:48px;
}

@media screen and (min-width: 1024px) {
    .faq-section {
        flex-direction: row;
    }
    .faq-list {
        width: 30%;
    }
    .faq-close-icon{
        display: flex;
    }
    .faq-content{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 60%;
        position: static;
        padding: 0;
        background: inherit;
        z-index: inherit;
    }
    .faq-details-section {
        flex-direction: row;
        justify-content: space-between;
    }
    .faq-modal-hide{
        display: flex;
    }
    .faq-close-icon{
        display: none;
    }
}

.faq-contact-us-title{
    width: 708.42px;
    height: 74px;
}

.faq-contact-content{
    max-width: 70%;
}

#faq-contact-flex-box{
    display:flex;
    gap: 40px;
}    

.faq-Contact-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px;
    gap: 40px;
    flex:1;
    min-height:380px;
}
    
.faq-Personal-shopper-box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 60px;
    gap: 20px;
    color: #FFFFFF;
    flex:1;
    min-height:380px;
}
    
.faq-contact-list{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

@media screen and (max-width: 1000px) {

    .faq-contact-content{
        max-width: 100%;
    }
     
    #faq-contact-flex-box{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .faq-Contact-box{
        align-items: flex-start;
        padding: 46px;
        gap: 40px;
        width: 100%;
        min-height: 360px;
    }

    .faq-Personal-shopper-box{
        align-items: flex-start;
        padding: 46px;
        gap: 20px;
        width: 100%;
        min-height: 360px;
    }

}

@media screen and (max-width: 500px)  {
    .faq-content-div{
        display:flex;
        flex-direction:column;
        gap:36px;
        align-items: center;
    }

    .like-dislike-div{
        display:flex;
        gap:0px;
        width:110px;
        justify-content: space-between;
    }

    .faq-Contact-box{
        align-items: flex-start;
        padding: 30px;
        gap: 40px;
        width: 100%;
        min-height: 360px;
    }
    
}