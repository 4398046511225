.profileBannerTitle {
  padding-left: 10%;
  padding-right: 10%;
}
#graph-container{
  height:700px;
}
.goal-container{
  display:flex;
  flex-direction:row;
  justify-content:center;
  margin-top:-400px;
  gap:40px;
}
.div-one{
  width:43%;
  /* flex:1; */
  height:220px;
  z-index: 1;
  box-shadow: 0px 5px 30px rgba(34, 34, 34, 0.15);
}
.personal-record-box{
  padding:30px 30px 0px 30px;
  width:100%;
  height:180px;
  background-color: #FFFFFF;
}
.div-two{
  width:43%;
  /* flex:1; */
  height:220px;
  z-index: 1;
  box-shadow: 0px 5px 30px rgba(34, 34, 34, 0.15);
}
.goal-box{
  padding:30px 30px 0px 30px;
  width:100%;
  height:180px;
  background-color: #FFFFFF;
  z-index: 1;
}
.pointsGridOne {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;
}
.pointsGridTwo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;
  height: 100px;
  border: 1px solid white;
}
.gridCol {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.ProfileProductBanner {
  padding: 10px;
}
.WishlistGrid {
  padding: 2px;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.singleWishlistWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 100px rgba(34, 34, 34, 0.15);
  padding: 20px;
}
.singleWishListText {
  width: 100%;
  height: 453px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
}
.singleWishlistImage {
  width: 100%;
  height: 350px;
}
.productsLimited3 {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
}
.lableTagButon {
  margin-top: 4px;
  margin-left: 3px;
}
.outOfStock {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  position: absolute;
  z-index: 2;
  background: rgba(34, 34, 34, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.point-col-lg {
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}
.graph-block {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 125%;
  transform: translateX(-10%);
}
.settingInputbox {
  flex-direction: column;
}
.menuBox {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  margin-top: 150px;
  margin-bottom: 100px;
}
.menuButton {
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid rgb(191,191, 191);
  padding: 20px;
}
.menuButton:hover {
  border-bottom: 1px solid rgb(229, 107, 46);
  color: rgb(191,191, 191);
}
.menuTop {
  display: none;
}
.orderHead {
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}
.countDownHorizontalItems > .horizontalScrollBlock > .grid-container > .main > .items > .item {
  width: 100%;
}

@media screen and (min-width: 500px) {
  .orderHead {
    flex-direction: row;
    margin-bottom: 0;
  }
}

@media screen and (max-width:620px) {
  #graph-container{
    height:880px;
  }
  .goal-container{
    flex-direction:column;
    margin-top:-605px;
    gap:0px;
  }
  .div-one{
    width:75%;
    height: max-content;
    margin:auto;
  }
  .div-two{
    width:75%;
    height: max-content;
    margin:auto;
    margin-top: 20px;
  }
  .personal-record-box{
    width:100%;
    margin:auto;
    height:max-content;
  }
  .goal-box{
    width:100%;
    margin:auto;
    height: max-content;
  }
  .received-points{
    padding-bottom:34px;
  }
}

@media screen and (min-width: 768px) {
  .menuBox {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
  }
  .profileBannerTitle {
    padding-left: 100px;
    padding-right: 100px;
  }
  .singleWishlistWrapper {
    flex-direction: row;
  }
  .singleWishListText {
    width: 40%;
    justify-content: center;
  }
  .singleWishlistImage {
    width: 50%;
    height: 453px;
  }
  .productsLimited3 {
    flex-direction: row;
  }
  .lableTagButon {
    margin-left: 190px;
  }
  .point-col-lg {
    flex: 0 0 50%;
  }
  .settingInputbox {
    flex-direction: row;
  }
}

@media screen and (min-width:620px) and (max-width:1280px) {
  #graph-container{
    height:820px;
  }
  .goal-container{
    flex-direction:column;
    margin-top:-520px;
    gap:0px;
  }
  .div-one{
    width:85%;
    height:220px;
    margin:auto;
  }
  .div-two{
    width:85%;
    height:220px;
    margin:auto;
    margin-top: 15px;
  }
  .personal-record-box{
    width:100%;
    margin:auto;
    height:180px;
  }
  .goal-box{
    width:100%;
    margin:auto;
    height:180px;
  }
}

@media screen and (min-width: 1024px) {
  .ProfileProductBanner {
    padding: 80px;
  }
  .WishlistGrid {
    padding: 8px;
  }
  .point-col-lg {
    flex: 0 0 25%;
  }
  .graph-block {
    width: calc(100% + 160px);
    transform: translateX(-80px);
  }
}

@media screen and (min-width: 1200px) {
  .menuBox {
    display: none;
  }
  .menuTop {
    display: inline-block;
  }
}

@media screen and (min-width: 1280px) {
  .graph-block {
    width: calc(100% + 200px);
    transform: translateX(-100px);
  }
}

/* Activation Frid Section */

.activationSectionGrid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: -100px;
}

.gridSection1 {
  height: 250px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
}

.gridSection2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.gridSection1Box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
  gap: 10px;
}

.gridSection2Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  gap: 20px;
}

.gridSection2Box:nth-child(1)  div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.gridSection2Box:nth-child(2)  div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.arrowActivation {
  transform: rotate(90deg);
}

.activationPragraphText {
  text-align: center;
}

.calculationDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

@media screen and (min-Width: 1024px) {

  .gridSection1 {
    height: 240px;
    flex-direction: row;
  }

  .gridSection2Box {
    flex-direction: row;
    gap: 30px;
    align-items: center;
  }

  .gridSection2Box:nth-child(1)  div:nth-child(1) {
    width: 360px;
    flex-direction: row;
  }

  .gridSection2Box:nth-child(2)  div:nth-child(1) {
    width: 360px;
    flex-direction: row;
  }

  .gridSection2Box:nth-child(3) {
    flex-direction: column;
  }

  .gridSection2Box:nth-child(3) .arrowActivation {
    transform: rotate(90deg);
  }

  .gridSection2Box:nth-child(3) .activationPragraphText {
    text-align: center;
  }

  .arrowActivation {
    transform: rotate(0deg);
  }

  .activationPragraphText {
    text-align: left;
  }

  .calculationDiv {
    flex-direction: row;
  }
}

@media screen and (min-Width: 1301px) {
  
  .gridSection2Box:nth-child(3) {
    flex-direction: row;
  }

  .gridSection2Box:nth-child(1)  div:nth-child(1) {
    width: 370px;
  }

  .gridSection2Box:nth-child(2)  div:nth-child(1) {
    width: 370px;
  }
  
  .gridSection2Box:nth-child(3) .arrowActivation {
    transform: rotate(0deg);
  }

  .gridSection2Box:nth-child(3) .activationPragraphText {
    text-align: left;
  }
}

@media screen and (min-Width: 1200px) {
  .activationSectionGrid {
    margin-top: 50px;
  }
}

/* Activation Frid Section END */



.profileCorrectStatementInfoText {
  max-width: 100%;
}


@media screen and (min-Width: 1200px) {
  .profileCorrectStatementInfoText {
    max-width: 72%;
  }
}