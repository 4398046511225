.shopBannerTitle, .shopBannerContent {
    padding-left: 10%;
    padding-right: 10%;
}
.shopBannerContent {
    /* max-width: 700px; */
    margin-top: 20px;
}
.shop-content{
    max-width: 100%;
    flex: 1
}
.product-grid{
    display: flex;
    flex-wrap: wrap;
}
.shop-content .product-grid .MuiGrid-item,
.shop-content .product-grid .MuiGrid2-root {
    display: flex;
    justify-content: center;
}
.resetFilterButton {
    justify-content: flex-start;
    margin-top: 20px !important;
}
.products-on-discount{
    display: none;
}
.play_pause_div{
    display:flex;
    width:100%;
    align-items:center;
    justify-content:center;
    position:absolute;
    left:0;
    right:0;
    top:80px;
    color:white;
}
.ThemeONEplay_pause_button_div{
    height:650px;
}
.ThemeTWOplay_pause_button_div{
    height:550px;
}
.ThemeTHREEplay_pause_button_div{
    height:550px;
}
#ThemeONEshop_banner_text{
    bottom:13%
}
#ThemeTWOshop_banner_text{
    bottom:8%
}
#ThemeTHREEshop_banner_text{
    bottom:8%
}
#play_pause{
    opacity:0.7;
    height:10vw;
    width:10vw;
}
.play_pause_button_div>#play_pause:hover{
    opacity: 1;
}
@media screen and (min-width: 768px) {
    .shopBannerTitle, .shopBannerContent {
        padding-left: 100px;
        padding-right: 100px;
    }
    .resetFilterButton {
        justify-content: flex-end;
        margin-top: 0 !important
    }
}

@media screen and (min-width: 1024px) {
    .products-on-discount{
        display: flex;
    }
}

