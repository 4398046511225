.countDownModal {
    display: flex;
    flex-direction: column;
}

.countDownTimer {
    display: flex;
    flex-direction: row;
    position: absolute;
    gap: 20px;
    top: 0;
    left: 0;
    padding: 20px 30px;
}

.countDownButtonM {
    display: flex;
    text-decoration: none;
}

.countDownButtonL {
    display: none;
    text-decoration: none;
}

.countDownTimeTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    min-width: 35px;
    text-align: center;
}

.countDownTimerContent {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
}

.countDownTimerContentBig {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    display: none;
}

.countDownModal .countDownBox {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 40px;
    padding-top: 186px;
}

.countDownModal .countDownBoxFalse {
    padding-top: 40px;
}

.countDownTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;
    white-space: normal;
}

.competitionStatus {
    margin-bottom: 5px;
}

.countDownContent {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px;
    white-space: normal;
}

.countDownButton {
    font-size: 14px;
}

@media screen and (min-width: 471px) {

    .countDownTitle {
        font-size: 30px;
        line-height: 44px;
        margin-bottom: 20px;
    }

    .countDownContent {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .countDownTimeTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 54px;
    }

    .countDownTimer {
        gap: 45px;
        padding: 20px 35px;
    }

    .countDownTimerContent {
        display: none;
    }

    .countDownTimerContentBig {
        display: block;
    }


    .countDownButtonM {
        display: none;
    }

    .countDownButtonL {
        display: flex;
    }
}

@media screen and (min-width: 642px) and (max-width: 769px) {
    .countDownModal .countDownBox {
        padding: 200px 172px 40px 40px;
    }

    .countDownModal .countDownBoxFalse {
        padding-Top: 50px;
    }
}


@media screen and (min-width: 769px) {
    .countDownModal {
        flex-direction: row;
        height: 600px;
        justify-content: flex-end;
    }

    .countDownModal .countDownBox {
        height: 100%;
        padding: 210px 40px 0px 100px;
        padding-left: 10%;
        justify-content: start;
    }

    .countDownModal .countDownBoxFalse {
        padding-left: 5%;
        padding-top: 150px;
    }

    .countDownTitle {
        font-size: 50px;
        line-height: 64px;
        margin-bottom: 20px;
    }

    .countDownContent {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 40px;
    }

    .countDownContentFalse {
        padding-right: 30%;
    }
}

@media screen and (min-width: 1025px) {
    .countDownModal .countDownBox {
        padding: 115px 60px 0px 100px;
        padding-left: 50%;
    }

    .countDownModal .countDownBoxFalse {
        padding-left: 10%;
    }

    .countDownContentFalse {
        padding-right: 48%;
    }
}

@media screen and (min-width: 1441px) {
    .countDownModal .countDownBox {
        padding: 130px 150px 0px 100px;
        padding-left: 45%;
    }

    .countDownModal .countDownBoxFalse {
        padding-left: 8%;
    }

    .countDownContentFalse {
        padding-right: 58%;
    }
}