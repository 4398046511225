.personalShopperDetails {
    width: 100%;
}

@media screen and (min-width: 1280px) {
    .personalShopperDetails {
        width: 60%;
    }
}

@media screen and (min-width: 1440px) {
    .personalShopperDetails {
        width: 50%;
    }
}

.writeToUs {
    padding: 100px 30px 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 100px;
}

@media screen and (min-width: 768px) {
    .writeToUs {
        padding: 120px 60px 64px;
    }
}

@media screen and (min-width: 1289px) {
    .writeToUs {
        flex-direction: row;
    }
}

@media screen and (min-width: 1024px) {
    .writeToUs {
        padding: 120px 105px 120px;
    }
}

.writeToUsNote {
    width: 100%;
    margin-bottom: 51px;
}

@media screen and (min-width: 768px) {
    .writeToUsNote {
        width: 60%;
        margin-bottom: 71px;
    }
}

@media screen and (min-width: 1440px) {
    .writeToUsForm {
        width: 60%;
        max-width: 600px;
    }
}

@media screen and (min-width: 1024px) {
    .writeToUsNote {
        width: 100%;
        margin-bottom: 43px;
    }
}

.wantaProduct {
    margin-top: 1015px;
}

@media screen and (min-width: 768px) {
    .wantaProduct {
        margin-top: 772px;
    }
}

@media screen and (min-width: 1024px) {
    .wantaProduct {
        margin-top: 800px;
    }
}

.personal-shopper-wrapper{
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 1rem;
    justify-content: space-between;
}
.personal-shopper-wrapper > div {
    width: 45%;
}


@media screen and (max-width: 1024px) {
    .personal-shopper-wrapper{
        flex-direction: column;
    }
    .personal-shopper-wrapper > div {
        width: 100%;
    }
}