.transaction-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 700px;
}

.transaction-table .table-head,
.transaction-table .table-body .tr
 {
  display: flex;
  flex-direction: row;
  padding: 15px;
}

.tcol-5 {
  flex: 0 0 41.66667%;
}

.tcol-4 {
  flex: 0 0 24.66667%;
}

.tcol-1 {
  flex: 0 0 9.66667%;
}

.tcol-2 {
  flex: 0 0 17%;
}

.tcol-3 {
  flex: 0 0 25%;
  text-align: right;
}

.transaction-table .table-body {
  max-height: 35.5em;
  overflow: auto;
}

.transaction-table .table-body .tr {
  background-color: #f2f2f2;
  font-size: 0.9em;
  vertical-align: middle;
}

.transaction-table .table-head .th {
  font-weight: bold;
}

.transaction-table .table-body .tr:nth-child(even) {
  background-color: white
}
