.cookie-popup-wrapper {
    left: 5vw;
    top: 10vh;
    position: fixed
}
@media screen and (min-width: 992px){ /* lg on bootstrap = 992 */
    .cookie-popup-wrapper {
        left: 100%;
        top: 0;
        position: absolute
    }
}