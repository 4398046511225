#message-block{
    border-radius: 14px;
    overflow: hidden;
}

.message-summary {
    display: flex;
    gap: 3rem;
    padding: 30px;
    flex-direction: column
}

.message-summary-one {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.message-detail-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 30px;
    flex-direction: column;
}

#message-block.MuiAccordion-root{
    box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}