.mt-75 {
  margin-top: 75px;
}

.termsOfTrade-title {
  font-size: 50px !important;
}
@media screen and (max-width: 450px) {
  .termsOfTrade-title {
    font-size: 25px !important;
  }
}
.mt-15{
    margin-top: 15px !important;
}

.mt-40{
    margin-top: 40px !important;
}
.mb-55{
    margin-bottom: 55px !important;
}
