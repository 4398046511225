/* 
@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Lora:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Work Sans:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Montserrat:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Dancing Script:regular,bold,italic&subset=latin,latin-ext'); 
*/

@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Dancing+Script:wght@400;500;600;700&family=Lora:wght@400;500;600;700&family=Montserrat:wght@100;200;300;400;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Work+Sans:wght@100;300;400;500;600;700&display=swap');

body {
  font-family: 'Poppins', cursive;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  box-shadow: none;
  outline: none;
}

*{ overflow: visible }
button, form 
{overflow: hidden;}

.LoginContainer {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}

.offcanvas-end{
  border-left: none !important;
}

@media only screen and (max-width: 500px) {
  .offcanvas-end{
    width: 100% !important;
  }
}

/* 
 * Placeholder CSS 
 */

/* Generic */
::placeholder {
  /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
  opacity: 1 !important; 
}

/* Internet Explorer 10-11 */
:-ms-input-placeholder { 
 /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
}

/* Microsoft Edge */
::-ms-input-placeholder { 
 /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
}

/* Chrome/Opera/Safari */
::-webkit-input-placeholder { 
  /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
}

/* Firefox 19+ */
::-moz-placeholder { 
  /* text-shadow: 0px 1px black; */
  letter-spacing: 0.5px;
  color: var(--placeholder-color) !important;
}
.large-screen-only {
  display: flex;
}
@media screen and (max-width: 1024px) {
  .large-screen-only {
    display: none;
  }
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.modal-content{
  max-width: 90vw !important;
  margin: auto;
}
.lazy-visible {
  display: flex;
}
.lazy-placeholder{
  background-image: url("https://picsum.photos/200/300");
}

.horizontal-scroll-wrapper {
  padding-left: 10%;
  margin-left: -10%;
  margin-right: -10%;
}

@media screen and (min-width: 1280px) {
  .horizontal-scroll-wrapper {
      padding-left: 100px;
      margin-left: -100px;
      margin-right: -100px;
  }
}