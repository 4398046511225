.taxCampaignHighlight {
    display: flex;
    flex-direction: column;
}

.taxCampaignHighlightButtonM {
    display: flex;
    text-decoration: none;
}

.taxCampaignHighlightButtonL {
    display: none;
    text-decoration: none;
}

.taxCampaignHighlight .taxCampaignHighlightText {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 40px;
    padding-top: 186px;
    gap: 2rem;
    align-items: flex-start;
}

@media screen and (min-width: 642px) and (max-width: 769px) {
    .taxCampaignHighlight .taxCampaignHighlightText {
        padding: 200px 172px 40px 40px;
    }
}

@media screen and (min-width: 471px) {

    .taxCampaignHighlightButtonM {
        display: none;
    }

    .taxCampaignHighlightButtonL {
        display: flex;
    }
}

@media screen and (min-width: 769px) {
    .taxCampaignHighlight {
        flex-direction: row;
        height: 600px;
        justify-content: flex-end;
    }

    .countDownTitle {
        font-size: 50px;
        line-height: 64px;
        margin-bottom: 20px;
    }

    .taxCampaignHighlight .taxCampaignHighlightText {
        height: 100%;
        padding: 210px 40px 0px 100px;
        padding-left: 10%;
        justify-content: start;
    }
}

@media screen and (min-width: 1025px) {
    .taxCampaignHighlight .taxCampaignHighlightText {
        padding: 115px 60px 0px 100px;
        padding-left: 50%;
    }
}

@media screen and (min-width: 1441px) {
    .taxCampaignHighlight .taxCampaignHighlightText {
        padding: 130px 150px 0px 100px;
        padding-left: 45%;
    }
}