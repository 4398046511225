.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "header header header" "title title footer" "main main main";
}

@media screen and (max-width: 500px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-areas: "header" "title" "main" "footer";
  }
}

.grid-item {
  padding: 3.5em 1em;
  font-size: 1em;
  font-weight: 700;
}

.main {
  grid-area: main;
  padding: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.items {
  position: relative;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  transition: all 0.2s;
  transform: scale(1);
  will-change: transform;
  user-select: none;
  cursor: pointer;
  padding-left: 2px;
}

.items.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.item {
  display: inline-block;
  min-height: 250px;
  margin: 1rem 10px 1.5rem 0rem;
}
.item:last-child{
  margin-right: 2px;
}


@media screen and (min-width: 768px) {
  .item {
    margin: 2rem 40px 2rem 0rem;
  }
}


.hs {
  display: grid;
  grid-gap: calc(var(--gutter) / 2);
  grid-template-columns: 10px;
  grid-template-rows: minmax(150px, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: calc(50% - var(--gutter) * 2);

  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(0.75 * var(--gutter));
  margin-bottom: calc(-0.25 * var(--gutter));
}

.hs:before,
.hs:after {
  content: "";
  width: 10px;
}

/* Range Slider CSS */

.horizontalScrollBlock input[type="range"] {
  -webkit-appearance: none;
}

.horizontalScrollBlock input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #e8e8e8;
}

.horizontalScrollBlock input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: #e8e8e8;
}

.horizontalScrollBlock input[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
.horizontalScrollBlock input[type="range"]::-ms-fill-lower {
  border-radius: 2.6px;
}
.horizontalScrollBlock input[type="range"]:focus::-ms-fill-lower {
  background: #e8e8e8;
}
.horizontalScrollBlock input[type="range"]::-ms-fill-upper {
  background: #e8e8e8;
  border-radius: 2.6px;
}

/* Special styling for WebKit/Blink */
.horizontalScrollBlock input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 4px;
  /* width: 100px; */
  /* border-radius: 3px; */
  background: #222222;
  cursor: pointer;
  margin-top: 0px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
.horizontalScrollBlock input[type="range"]::-moz-range-thumb {
  border: none;
  height: 4px;
  /* width: 100px; */
  /* border-radius: 3px; */
  background: #222222;
  cursor: pointer;
}

/* All the same stuff for IE */
.horizontalScrollBlock input[type="range"]::-ms-thumb {
  border: none;
  height: 4px;
  /* width: 100px; */
  /* border-radius: 3px; */
  background: #222222;
  cursor: pointer;
}
